import React from "react";
import styled from "styled-components";
import Topbar from "src/components/topbar.js";

import SEO from "src/components/seo.js";

const Container = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
    margin: 50px 0;
    position: relative;

`;

const Wrapper = styled.div`
    font-family: "URWAccidalia", sans-serif;
    color: white;
    max-width: 650px;
    padding: 40px;
`;

const Block = styled.div`
    margin: 40px 0;

    p {
        font-family: "Univers";
        line-height: 1.2;
        margin: 10px 0;
    }
`;

const FAQ = () => {
    return (
        <>
            <SEO title="FAQ" />
            <Topbar></Topbar>
            <Container>
                <Wrapper>
                    <Block>
                        <h1>How to create an account on the HXOUSE App?</h1>
                        <p>
                            1. Download the Instagram app from the App Store (iPhone) or Google Play Store (Android).
                        </p>
                        <p>
                            2. Once the app is installed, tap the HXOUSE icon on your home screen and to open it.
                        </p>
                        <p>
                            3. On the App’s landing page Type in your email, mobile number and the reference code sent to you in the app announcement email.
                        </p>
                        <p>
                            4. Verify your account through an OTP number sent to your mobile and create a profile.
                        </p>
                    </Block>
                    <Block>
                        <h1>How do I update my personal information?</h1>
                        <p>
                            To update your personal information click on the “ME” icon at the bottom right of the App home page, click “EDIT PROFILE”, update your photos or personnel changes and press “SAVE UPDATES.
                        </p>
                    </Block>
                    <Block>
                        <h1>How do I Book Events?</h1>
                        <p>
                            To book Events click on the “EVENTS” icon at the bottom the left side of the App home page, click on the event you are looking for “BOOK A SEAT” or “REQUEST TO BOOK” depending on the nature of the event.
                        </p>
                    </Block>
                    <Block>
                        <h1>What happens if I book a seat at the event?</h1>
                        <p>
                            If you select “BOOK A SEAT” you will automatically receive an email notification confirming your seat at the event.
                        </p>
                    </Block>
                    <Block>
                        <h1>What happens if I request to book an event?</h1>
                        <p>
                            If you select “REQUEST TO BOOK,” write a reason for attendance and click “SUBMIT THE REQUEST” Onse your request is processed you will get notified via email if your seat at the event is secured.
                        </p>
                    </Block>
                    <Block>
                        <h1>Where do I find information about the speakers and facilitators?</h1>
                        <p>
                            To find Information about the speakers and facilitators click the icon at the bottom the left side of the App home page, click on the event you are looking for and scroll down to find more information about the events and speakers, event’s date and time and location. Click on speaker profiles for more information about them.
                        </p>
                    </Block>
                    <Block>
                        <h1>Where can I see my events schedule?</h1>
                        <p>
                            To view your event schedule click on the “ME” icon at the bottom right of the App home page, then click on “EVENTS.”
                        </p>
                    </Block>
                    <Block>
                        <h1>How do I post something on the Feed?</h1>
                        <p>
                            To post on the feed, click on the “+” icon at the bottom centre of the App home page, the click on “CREATE FEED, ” write what's on your mind or add a photo then click on “POST NOW” button. To view your post click on the “HOME” icon at the bottom right of the app home screen, You can view the comments and likes section of your post under the post.
                        </p>
                    </Block>
                    <Block>
                        <h1>How do I like or comment on the posts I see on Feed?</h1>
                        <p>
                            To like a post on the feed click the “THUMBS UP” icon under the post. To comment on a post on the feed, type your comment in the comment section and press the “SEND” icon.
                        </p>
                    </Block>
                    <Block>
                        <h1>How do I like to delete my post from the Feed?</h1>
                        <p>
                            While you cannot edit a post you can delete it, selecting your posting and clicking the “DELETE POST” button.
                        </p>
                    </Block>
                    <Block>
                        <h1>How do I view profiles of others on the app?</h1>
                        <p>
                            To view profiles of others click the “TENANTS” icon at the bottom right of the App home screen then click on the Tenant or Staff member profile.
                        </p>
                    </Block>
                    <Block>
                        <h1>How do I send a message to another user?</h1>
                        <p>
                            To send a message to another user, click the “TENANTS” icon at the bottom right of the App home screen click on the Tenant or Staff member profile, then click “MESSAGE” button, type in the type section and click the “SEND” icon.
                        </p>
                    </Block>
                </Wrapper>
            </Container>
        </>
    );
};

export default FAQ;
